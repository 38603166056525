import React from "react"
import { Link } from "gatsby"
import Contact from "../components/contact"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/why.scss"
import Section from "../components/section"
import SectionSubtitle from "../components/sectionSubtitle"
import Footer from "../components/footer"

const Anxiety = () => (
  <Layout>
    <SEO
      lang="it"
      title="Disturbi d'ansia | Stefano Maffina | Psicologo Psicoterapeuta cognitivo-comportamentale | Gardone Val Trompia"
      description="Descrizione dei disturbi d'ansia di Stefano Maffina, psicologo Psicoterapeuta cognitivo-comportamentale"
    />
    <div className="navbar paddings-lr">
      <div style={{ width: "1050px", margin: "0 auto", paddingLeft: "16px" }}>
        <Link to="/">
          <span>Indietro</span>
        </Link>
      </div>
    </div>
    <Section isAbout color="#ebebeb">
      <div style={{ paddingTop: "64px" }}>
        <div className="section-subtitle">
          <h2
            data-aos="custom-fade"
            data-aos-duration="1000"
            style={{ color: "#121212" }}
          >
            <span style={{ fontWeight: 700, textDecoration: "italic" }}>
              Disturbi d'ansia
            </span>{" "}
          </h2>
        </div>{" "}
        <div
          style={{ marginBottom: "64px", paddingTop: "64px" }}
          className="paddings-lr why-content"
        >
          <p>
            Comprendono tutti quei disturbi che condividono caratteristiche di
            paura e ansia eccessive verso qualcosa.
          </p>
          <p>
            I disturbi d’ansia sono differenti tra loro, per la tipologia di
            oggetti e situazioni che provocano l’emozione di ansia e paura, e
            differiscono dalla normale paura o ansia evolutive perché sono
            eccessivi e/o persistenti ed invalidanti nella vita quotidiana della
            persona. I processi di evitamento esperienziale, cioè la tendenza ad
            evitare o sopprimere le emozioni spiacevoli e difficili da gestire,
            sono ciò che caratterizza i disturbi d’ansia.
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Attacchi di panico:</span> sono
            caratterizzati da un forte aumento del sistema nervoso autonomo per
            cui gli individui sperimentano estrema paura e terrore, pensieri di
            morte e perdita di controllo, e un bisogno impellente di fuggire ed
            allontanarsi da qualunque posto si trovino.
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Fobie Specifiche:</span> è una
            paura marcata, intensa, immediata e persistente nei confronti di un
            oggetto o di una situazione particolare. Questa risposta di allarme
            è accompagnata da un forte impulso a fuggire dall’oggetto o dalla
            situazione.
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>
              Disturbo d’ansia sociale:
            </span>{" "}
            caratterizzato da una paura forte e persistente e dall’evitamento di
            situazioni che implicano interazione sociale e giudizio da parte
            degli altri. La persona che ne soffre si ritiene socialmente
            inadeguata e, in genere, evita o fugge da situazioni sociali.
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>
              Disturbo di panico e agorafobia:
            </span>{" "}
            è caratterizzato da attacchi di panico ricorrenti e inaspettati, e
            ansia riguardo alla possibilità di futuri attacchi di panico.
            L’agorafobia è la paura che si verifichi un attacco di panico in
            luoghi particolari.
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>
              Disturbo d’ansia generalizzato:
            </span>{" "}
            è caratterizzato da una forte preoccupazione per una serie di eventi
            e attività che si manifestano per la maggior parte dei giorni e per
            almeno 6 mesi, causando disagio significativo e compromettono il
            benessere e la quotidianità della persona.
          </p>
        </div>
      </div>
    </Section>
    <Contact />
    <Footer />
  </Layout>
)

export default Anxiety

{
  /* <Layout location={location} title={siteTitle}>
<Seo
  lang="it"
  title="Stefano Maffina | Psicologo clinico | Gardone Val Trompia"
  description="Stefano Maffina, psicologo clinico a Gardone Val Trompia, BS"
/>
<div className="fix-content-width">
  <NavBar />
  <div className="singlepage-container">
    <div className="section-subtitle singlepage-title">
      <h2
        data-aos="custom-fade"
        data-aos-duration="1000"
        style={{ color: "#121212" }}
      >
        <span style={{ fontWeight: 700, textDecoration: "italic" }}>
          Blog
        </span>
      </h2>
      <div
        data-aos="custom-border"
        data-aos-duration="1000"
        className="singlepage-title-border"
      ></div>
    </div>
    <ol
      style={{ listStyle: `none`, margin: 0, paddingTop: "32px" }}
      className="paddings-lr postlist-container"
    >
      {posts.map(post => {
        const title = post.frontmatter.title || post.fields.slug

        return (
          <li key={post.fields.slug} className="postlist-li">
            <Link to={post.fields.slug} itemProp="url">
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header style={{ paddingBottom: "16px" }}>
                  <h2 style={{ fontSize: "20px" }}>
                    <span itemProp="headline">{title}</span>
                  </h2>
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "200",
                      letterSpacing: "5%",
                      textTransform: "uppercase",
                    }}
                  >
                    {post.frontmatter.date}
                  </span>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                    style={{ fontSize: "15px" }}
                  />
                </section>
              </article>
            </Link>
            <br />
            <div
              style={{ borderBottom: "0.5px solid rgba(0,0,0,0.1)" }}
            ></div>
          </li>
        )
      })}
    </ol>
  </div>
</div>
<Contact />
<Footer />
</Layout> */
}
